



































































































































































































































































































import Vue from "vue";
import _ from "lodash";

import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import notificationMixin from "../../mixin/notification";
import { OrderCode } from "../../model/entity/orderSetting";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component("Cart", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHCartCardSkeleton: () =>
      import("../../components/components/SHCartCardSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QRemainMenuOption: () => import("../../components/elements/QRemainMenuOption.vue"),
    QCartListTotal: () => import("../../components/elements/QCartListTotal.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      orders: [],
      tableSessionId: this.$route.params.table_session_id,
      isNaizei: "",
      isQuick: "",
      isDeliver: "0",
      listTotal: [],
      modal: {
        data: {
          order: {},
          menu_options: undefined,
        },
        errors: {
          order: {},
        },
      },

      errors: "",
      mode: "",
      isLoading: false,

      staffId: store.state.staff.id,
      langKey: store.state.langKey,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchOrders();
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    totalQuantity(): number {
      let target = _.find(
        this.listTotal,
        (listTotal) => listTotal.tax_rate == null
      );

      return target.quantity;
    },
    totalPrice(): number {
      let total = 0;
      for (const order of this.orders) {
        if (order.prices.total.exclude !== null) {
          total += order.prices.total.exclude;
        } else {
          total += order.prices.total.include;
      }
      }

      return total;
    },
    taxType(): string {
      if (this.isNaizei === true) {
        return " (" + translate("hall-0096", "税込") + ")";
      }
      if (this.isNaizei === false) {
        return " (" + translate("hall-0097", "税抜") + ")";
      }
    },
    onLoadDisable: function () {
      if (this.isLoading) {
        return "disabled";
      } else {
        return "";
      }
    },
    isModalDone(): boolean {
      return this.mode === "done";
    },
    isModalOption(): boolean {
      return this.mode === "option";
    },
    isModalReceive(): boolean {
      return this.mode === "receive";
    },
  },
  methods: {
    handleClickIncrease() {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(+1);
    },
    handleClickDecrease() {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(-1);
    },
    handleClickModifyOrder: function (item) {
      if (this.isLoading) {
        return;
      }

      this.mode = "option";
      this.modal.data.menu_options = item.menu.menu_options;
      this.modal.data.order = _.cloneDeep(item);
    },
    handleClickOrder: function () {
      if (this.isLoading) {
        return;
      }
      if (store.state.shopSetting.is_select_receive) {
        this.mode = "receive";
      } else {
        this.isLoading = true;
        this.order();
      }
    },
    handleClickOrderReceive: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.order();
    },
    handleClickSubmitModify: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitModify();
    },
    handleClickCloseModal: function () {
      if (this.isLoading) {
        return;
      }

      this.closeModal();
    },
    handleClickSubmitDelete: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitDelete();
    },

    updateQuantity: function (offset) {
      const quantity = Math.max(1, this.modal.data.order.quantity + offset);
      this.modal.data.order.quantity = quantity;
    },
    optionValueRadio(option_menu) {
      const result = _.find(
        this.modal.data.order.tmp_options,
        function (row: any) {
          if (!row.menus_menu_option) {
            return false;
          }
          return option_menu.id === row.menus_menu_option.id;
        }
      );
      return result ? true : false;
    },
    optionValueCheckbox(option_menu) {
      const result = _.findIndex(
        this.modal.data.order.tmp_options,
        function (row: any) {
          return option_menu.id === row.menus_menu_option_id;
        }
      );
      return result !== -1;
    },
    optionChangeRadio(event, option) {
      _.remove(this.modal.data.order.tmp_options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option.id === row.menus_menu_option.menu_option_id;
      });
      this.modal.data.order.tmp_options.push({
        menus_menu_option_id: _.clone(event.target.value),
        menus_menu_option: {
          menu_option_id: option.id,
        },
        quantity: this.modal.data.order.quantity,
      });
    },
    optionChangeCheckbox(event, option) {
      if (!event.target.checked) {
        const result = _.findIndex(
          this.modal.data.order.tmp_options,
          function (row: any) {
            return event.target.value === row.menus_menu_option_id;
          }
        );
        if (result !== -1) {
          this.modal.data.order.tmp_options.splice(result, 1);
        }
      } else {
        this.modal.data.order.tmp_options.push({
          menus_menu_option_id: _.clone(event.target.value),
          menus_menu_option: {
            menu_option_id: option.id,
          },
          quantity: this.modal.data.order.quantity,
        });
      }
    },
    closeModal: function () {
      this.mode = "";
      this.isLoading = false;
      this.isDeliver = "0";
      this.modal.data.order = {};
    },
    redirectToCheck() {
      this.$router.push(
        `/${this.prefix}/${this.shoppix}/table-session/check-quick/${this.tableSessionId}`
      );
    },
    redirectToHome() {
      this.$router.push(`/${this.prefix}/${this.shoppix}/home`);
    },

    order: function () {
      if (this.isQuick === true) {
        this.redirectToCheck();
        return;
      }

      let message = "";
      api
        .order_order({
          code: OrderCode.CODE_BY_HALLSTAFF,
          id: this.tableSessionId,
          staff_id: this.staffId,
          is_deliver: this.isDeliver,
        })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.mode = "done";
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitModify: function () {
      let message = "";
      api
        .order_edit_tmp({ order: this.modal.data.order })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },
    submitDelete: function () {
      let message = "";
      api
        .order_delete_tmp({ order: this.modal.data.order })
        .then((response) => {
          message = response.data.message;
          this.showSuccessNotification(message);
          this.closeModal();
          this.fetchOrders();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.modal.errors.order = message;
          this.isLoading = false;
        });
    },

    fetchOrders() {
      this.isLoading = true;
      let message = "";
      api
        .hallCart({ lang: this.langKey, table_session_id: this.tableSessionId })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0048", "データの取得に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          if (
            response.data.data.isQuick === true &&
            response.data.data.orders.length === 0
          ) {
            this.redirectToHome();
            return;
          }
          this.orders = response.data.data.orders;
          this.isNaizei = response.data.data.isNaizei;
          this.isQuick = response.data.data.isQuick;
          this.listTotal = response.data.data.listTotal;
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
  },
  filters: {
    priceFilter(price: number): string {
      const formatter = new Intl.NumberFormat("ja-JP");

      return formatter.format(price);
    },
    orderItemStyle(order) {
      if (order.quantity === 0) {
        return { opacity: 0.5 };
      } else {
        return {};
      }
    },
    taxPrice(prices): number {
      if (prices.tanka.exclude !== null) {
        return prices.tanka.exclude;
      }
      return prices.tanka.include;
    },
    taxTotal(prices): number {
      if (prices.total.exclude !== null) {
        return prices.total.exclude;
      }
      return prices.total.include;
    },
    numberFormat(number) {
      if (typeof number !== "number") {
        return number;
      }
      return number.toLocaleString();
    },
  },
});
